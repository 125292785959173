/*team details css*/
.cta-box {
    padding-top: 40px;
    margin-bottom: 55px;
    &__title {
        font-size: 48px;
        color: $white;
        line-height: 60px;
        margin-bottom: 25px;
    }
    p {
        color: $white;
    }
}

.team-details {
    &__thumb {
        height: 100%;
        img {
            width: 100%;
        }
    }
    &__content {
        @include respond(lg) {
            margin-top: 50px !important;
        }
        @include respond(md) {
            margin-top: 50px !important;
        }
        .name {
            font-size: 30px;
            line-height: 26px;
            margin-bottom: 16px;
        }
        .designation {
            color: #6B727E;
        }
        .bio {
            .title {
                font-size: 22px;
                margin-bottom: 20px;
            }
        }
    }
}

.team-info {
    @include d-flex;
    padding-top: 17px;
    padding-bottom: 17px;
    @include respond(md) {
        padding-left: 20px;
        padding-right: 20px;
    }
    &__item {
        @include flexWidth(33.3333%);
        padding: 15px 0px;
        padding-left: 60px;
        @include respond(md) {
            padding-left: 0px;
            @include flexWidth(100%);
            text-align: center;
        }
        &:not(:last-child) {
            padding-right: 40px;
            border-right: 1px solid #8697B2;
            @include respond(md) {
                padding-right: 0px;
                border-bottom: 1px solid #8697B2;
                border-right: 0px;
                padding-bottom: 30px;
            }
        }
        &:not(:first-child) {
            @include respond(md) {
                padding-top: 30px;
            }
        }
        &--title {
            color: $white;
            font-size: 22px;
            margin-bottom: 10px;
        }
        a {
            color: $white;
        }
    }
    .social-links {
        a {
            font-size: 15px;
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
}

.education-box {
    @include respond(lg) {
        margin-top: 80px !important;
    }
    @include respond(md) {
        margin-top: 80px !important;
    }
    &__title {
        font-size: 16px;
        margin-bottom: 10px;
    }
    span {
        margin-bottom: 12px;
    }
}