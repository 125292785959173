/*testimonial css*/
.testimonial-area {
    position: relative;
    @include respond(lg) {
        padding-bottom: 150px !important;
        padding-top: 152px !important;
    }
    @include respond(md) {
        padding-bottom: 120px !important;
        padding-top: 120px !important;
    }
    .shape {
        position: absolute;
        height: 100%;
        content: "";
        left: -35px;
        top: -35px;
        @include respond(laptop) {
            left: -260px;
        }
        @include respond(lg) {
            left: -135px;
            top: -45px;
            width: 50%;
        }
        &__2 {
            left: auto;
            right: 15px;
            top: -380px;
            @include respond(laptop) {
                right: -150px;
            }
            @include respond(lg) {
                right: -120px;
                top: -495px;
                width: auto;
            }
        }
    }
    overflow: hidden;
    &__2 {
        margin: 0px 120px;
        margin-bottom: -225px;
        overflow: auto;
        @media (max-width: 1690px) { 
            margin: 0px 95px;
            margin-bottom: -225px;
        }
        @media (max-width: 1690px) { 
            margin: 0px 30px;
            margin-bottom: -225px;
        }
        @include respond(laptop) {
            margin: 0px 15px;
            margin-bottom: -225px;
        }
        @include respond(md) {
            margin: 0px 15px;
            margin-bottom: -225px;
        }
    }
    &__3 {
        background: $blue-dark;
        margin-bottom: 0px;
    }
}


.testimonial-item {
    @include d-flex;
    padding-top: 25px;
    @include respond(md) {
        flex-direction: column;
    }
    &__thumb {
        @include flexWidth(400px);
        height: auto;
        position: relative;
        @include transform(translate(35px, -130px));
        @media (max-width: 1250px) {
            @include transform(translate(50px, -130px));
        }
        @include respond(lg) {
            @include transform(translate(0px, -130px));
            padding: 25px;
            @include flexWidth(300px);
        }
        @include respond(md) {
            @include transform(translate(0px, 0px));
        }
        .t-shape {
            position: absolute;
            height: 100%;
            content: "";
            left: -35px;
            top: -35px;
            @include respond(lg) {
                top: 0;
                left: 0;
            }
            @include respond(md) {
                left: 0px;
                top: 0px;
            }
            img {
                width: auto;
                animation: rotate 10s infinite linear;
            }
        }
        .author {
            position: absolute;
            width: 330px;
            border-radius: 50%;
            @include respond(lg) {
                width: 250px;
            }
            img {
                width: 100%;
                border-radius: 50%;
            }
        }
        .quote {
            position: absolute;
            right: -40px;
            top: -35px;
            z-index: -1;
            @include respond(lg) {
                right: -5px;
                top: -10px;
                width: 80px;
            }
        }
    }
    &__content {
        @include flexWidth(770px);
        padding-left: 100px;
        @include respond(lg) {
            @include flexWidth(580px);
            padding-left: 25px;
        }
        @include respond(md) {
            @include flexWidth(100%);
            padding-left: 0px;
            margin-top: 40px;
        }
        p {
            line-height: 27px;
            color: $white;
        }
        .name {
            color: $white;
            font-size: 22px;
        }
        .designation {
            font-weight: 400;
            font-size: 16px;
            color: $white;
        }
    }
}

.testimonial {
    .owl-stage-outer {
        overflow: initial;
    }
    .owl-item {
        opacity: 0;
    }
    .owl-item.active {
        opacity: 1;
    }
    @include respond(md) {
        margin-top: 50px;
    }
    &__2, &__3 {
        .testimonial-item {
            padding: 0px;
        }
        img {
            width: auto !important;
            display: inline-block !important;
        }
        &--icon {
            text-align: center;
            width: 100%;
        }
        &--content {
            padding: 0px 40px;
            @media (max-width: 1350px) {
                padding: 0px 80px;
            }
            @media (max-width: 450px) {
                padding: 0px 58px;
            }
            p {
                font-size: 40px;
                line-height: 55px;
                color: $primary-color;
                @media (max-width: 1400px) {
                    font-size: 32px;
                    line-height: 40px;
                }
                @media (max-width: 1350px) {
                    font-size: 24px;
                    line-height: 38px;
                }
                @include respond(xs) {
                    font-size: 20px;
                    line-height: 32px;
                }
                @media (max-width: 450px) {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            &--2 {
                p {
                    color: $white;
                }
            }
        }
        &--author {
            @include d-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media (max-width: 400px) {
                flex-direction: column;
            }
            .thumb {
                img {
                    border-radius: 50%;
                }
                @include flexWidth(100px);
                @media (max-width: 400px) {
                    margin-bottom: 15px;
                    @include flexWidth(70px);
                }
            }
            .content {
                padding-left: 25px;
                text-align: left;
                @media (max-width: 400px) {
                    padding-left: 0px;
                }
                .name {
                    font-size: 22px;
                    margin-bottom: 5px;
                }
                &__2 {
                    .name {
                        color: $white;
                    }
                    .designation {
                        color: $white;
                        opacity: .70;
                    }
                }
            }
        }
        .owl-nav {
            div {
                position: absolute;
                left: -120px;
                top: 50%;
                @include transition(.3s);
                &::after {
                    position: absolute;
                    left: -13px;
                    top: 0px;
                    width: 35px;
                    height: 35px;
                    border: 2px solid #0C2957;
                    border-radius: 50%;
                    content: "";
                    @include transition(.3s);
                }
                &:hover {
                    color: $theme-color;
                    &::after {
                        border-color: $theme-color
                    }
                }
                @include transform(translateY(-50%));
                font-size: 36px;
                color: $primary-color;
                @media (max-width: 1610px) { 
                    left: -80px;
                }
                @include respond(laptop) {
                    left: -60px;
                }
                @media (max-width: 1400px) {
                    left: -40px;
                }
                @media (max-width: 1250px) {
                    left: 30px;
                }
                @include respond(md) {
                    left: 10px;
                }
                @media (max-width: 450px) {
                }
            }
            .owl-next {
                left: auto;
                right: -120px;
                &::after {
                    left: auto;
                    right: -13px;
                }
                @media (max-width: 1610px) {
                    right: -80px;
                }
                @include respond(laptop) {
                    right: -60px;
                }
                @media (max-width: 1400px) {
                    right: -20px;
                }
                @media (max-width: 1250px) {
                    right: 30px;
                }
                @include respond(md) {
                    right: 10px;
                }
            }
        }
    }
}
