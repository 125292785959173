/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
@for $i from 1 through 20 {
    .mt-none-#{5 * $i}{margin-top: -5px * $i !important;}
}

/*-- Margin Top --*/
@for $i from 1 through 40 {
    .mt-#{5 * $i}{margin-top: 5px * $i !important;}
}

/*-- Margin Bottom --*/
@for $i from 1 through 40 {
    .mb-#{5 * $i}{margin-bottom: 5px *$i !important;}
}
/*-- Margin Left --*/
@for $i from 1 through 40 {
    .ml-#{5 * $i}{margin-left: 5px * $i !important;}
}

/*-- Margin Right --*/
@for $i from 1 through 40 {
    .mr-#{5 * $i}{margin-right: 5px *$i !important;}
}

/*-- Padding Top --*/
@for $i from 1 through 40 {
    .pt-#{5 * $i}{padding-top: 5px *$i}
}

/*-- Padding Bottom --*/
@for $i from 1 through 40 {
    .pb-#{5 * $i}{padding-bottom: 5px *$i;}
}

/*-- Padding Left --*/
@for $i from 1 through 40 {
    .pl-#{5 * $i}{padding-left: 5px *$i;}
}

/*-- Padding Right --*/
@for $i from 1 through 40 {
    .pr-#{5 * $i}{padding-right: 5px *$i;}
}