/* hero css */

.hero {
    &__item {
        padding: 0px 120px;
        padding-right: 0px;
        background-color: #FF442B;
        position: relative;
        @include respond(laptop) {
            padding: 0px 50px;
        }
        @media (max-width: 1300px) {
            padding: 0px 15px;
        }
    }
    &__content {
        padding: 245px 0px;
        padding-top: 230px;
        position: relative;
        @media (max-width: 1726px) {
            padding-top: 220px;
            padding-bottom: 210px;
        }
        @include respond(laptop) { 
            padding-top: 180px;
            padding-bottom: 200px;
        }
        @include respond(lg) {
            padding-top: 160px;
            padding-bottom: 180px;
        }
        p {
            font-size: 36px;
            color: $white;
            position: relative;
            z-index: 3;
            @media (max-width: 1726px) { 
                font-size: 30px;
            }
            @include respond(laptop) {  
                font-size: 24px;
            }
            @include respond(lg) {
                font-size: 20px;
            }
        }
        .shape {
            position: absolute;
            right: 55px;
            bottom: 0;
            z-index: 1;
            @include respond(lg) {
                right: -25px;
            }
            @media (max-width: 450px) {
                right: -235px;
            }
        }
        &--2 {
            min-height: 570px;
            width: 570px;
            padding: 0px;
            z-index: 1;
            overflow: hidden;
            @include d-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @include respond(md) {
                min-height: 430px;
                width: 430px;
            }
            @include respond(xs) {
                margin: 0px 0px 0px auto;
            }
            @include respond(sm) {
                margin: 0px 0px 0px auto;
            }
            @media (max-width: 500px) {
                min-height: 380px;
                width: 380px;
            }
            @media (max-width: 400px) {
                min-height: 330px;
                width: 330px;
            }
            &::after {
                position: absolute;
                left: 0;
                top: 0;
                background-color: $white;
                animation: sliderShape 10s infinite;
                border-radius: 100%;
                content: "";
                width: 100%;
                height: 100%;
                z-index: -1;
            }
            z-index: 1;
            .hero-icon {
                margin-bottom: 25px;
            }
            .hero-text {
                h2 {
                    font-size: 60px;
                    line-height: 65px;
                    margin-bottom: 25px;
                    @include respond(md) {
                        font-size: 46px;
                        line-height: 54px;
                        margin-bottom: 15px;
                    }
                    @include respond(xs) {
                        font-size: 32px;
                        line-height: 38px;
                    }
                    @media (max-width: 400px) {
                        font-size: 26px;
                        line-height: 32px;
                    }
                }
                p {
                    font-size: 30px;
                    color: $primary-color;
                    font-weight: 700;
                    @include respond(md) { 
                        font-size: 20px;
                    }
                    @include respond(xs) { 
                        font-size: 16px;
                    }
                }
                .site-btn {
                    &__2 {
                        margin-top: 48px;
                        padding: 17px 29.5px;
                        @include respond(md) {
                            margin-top: 30px;
                        }
                        @include respond(xs) {
                            margin-top: 25px;
                            padding: 9.5px 18px;
                            font-size: 12px;
                            min-width: auto;
                        }
                    }
                }
            }
            .dot-shape {
                position: absolute;
                width: 105%;
                top: -45px;
                left: -5px;
            }
        }
    }
    &__title {
        color: $primary-color;
        font-size: 90px;
        line-height: 110px;
        color: $white;
        font-weight: 700;
        position: relative;
        z-index: 3;
        margin-bottom: 45px;
        @media (max-width: 1726px) { 
            font-size: 70px;
            line-height: 90px;
        }
        @include respond(laptop) {
            font-size: 60px;
            line-height: 80px;
            margin-bottom: 30px;
        }
        @include respond(lg) {
            font-size: 50px;
            line-height: 70px;
            margin-bottom: 20px;
        }
        @include respond(xs) {
            font-size: 40px;
            line-height: 60px;
            margin-bottom: 20px;
        }
        @include respond(sm) {
            font-size: 50px;
            line-height: 70px;
            margin-bottom: 20px;
        }
        @media (max-width: 400px) {
            font-size: 36px;
            line-height: 45px;
        }
        
    }
    &__image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        @include respond(laptop) {
            width: 65%;
        }
        @include respond(lg) {
            width: 65%;
        }
        @media (max-width: 450px) {
            display: none !important;
        }
        img {
            width: auto;
            object-fit: cover;
        }
    }
    .site-btn {
        margin-top: 63px;
        position: relative;
        z-index: 2;
        @include respond(laptop) {
            margin-top: 50px;
        }
        @include respond(lg) {
            margin-top: 30px;
        }
        img {
            display: inline-block;
        }
        &:hover {
            background: $primary-color;
            color: $white;
        }
    }
    .owl-nav {
        position: absolute;
        z-index:999;
        right: 50px;
        top: 50%;
        @include transform(translateY(-50%));
        @include respond(lg) {
            right: 30px;
        }
        @include respond(xs) {
            right: 15px;
        }
        @include respond(sm) {
            right: 30px;
        }
        div {
            font-size: 30px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            color: $white;
            opacity: .20;
            border: 2px solid $white;
            line-height: 60px;
            text-align: center;
            @include transition(.3s);
            @include respond(xs) {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
            @include respond(sm) {
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
            &:hover {
                color: $white;
                background: $theme-color;
                border-color: $theme-color;
                opacity: 1;
            }
        }
        div.owl-next {
            margin-top: 15px;
        }
    }
}

.hero__2 {
    padding-right: 120px;
    padding-top: 180px;
    padding-bottom: 150px;
    position: relative;
    background-size: cover;
    background-position: center center;
    @media (max-width: 1600px) {
        padding-right: 80px;
    }
    @include respond(lg) {
        padding-right: 25px;
    }
    @include respond(md) {
        padding-top: 130px;
        padding-bottom: 90px;
    }
    @include respond(xs) {
        padding-top: 160px;
        padding-bottom: 160px;
        padding-right: 0px;
    }
    .shape {
        position: absolute;
        left: 0;
        bottom: 0;
        @include respond(xs) {
            bottom: -70px;
        }
        @media (max-width: 500px) {
            bottom: -40px;
        }
        @media (max-width: 450px) {
            bottom: -10px;
        }
    }
    .custom-col-width {
        @media (max-width: 1400px) {
            @include flexWidth(45%);
        }
        @media (max-width: 1360px) {
            @include flexWidth(50%);
        }
        @include respond(lg) {
            @include flexWidth(60%);
        }
        @include respond(xs) {
            @include flexWidth(90%);
        }
        @include respond(sm) {
            @include flexWidth(90%);
        }
        @media (max-width: 450px) {
            @include flexWidth(100%);
        }
    }
    .shape-pattern {
        position: absolute;
        left: 0;
        bottom: 0;
        img {
            @include respond(md) {
                height: 360px;
            }
        }
    }
}

