/*project details css*/
.project-details {
    padding: 55px 50px;
    @include respond(lg) {
        margin-top: 50px;
    }
    @include respond(md) {
        margin-top: 50px;
    }
    li {
        font-size: 30px;
        font-weight: 700;
        font-family: $headingfont;
        color: $white;
        padding-bottom: 20px;
        &:not(:last-child) {
            margin-bottom: 33px;
            border-bottom: 1px solid #2A4877;
        }
        span {
            font-family: $defaultfont;
            display: block;
            font-weight: 400;
            font-size: 16px;
            opacity: .90;
            margin-top: 18px;
            color: $white;
        }
    }
}

.project-thumb {
    height: 100%;
}