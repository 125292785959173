/*news css*/

.news-area {
    .section-header {
        margin-bottom: 40px !important;
    }
    &__2 {
        .section-header {
            margin-bottom: 65px !important;
        }
    }
}

.news-right {
    margin-top: 60px;
    @include respond(lg) {
        margin-bottom: 60px !important;
        margin-top: 0px;
        text-align: left !important;
    }
}

.post-box {
    position: relative;
    &__thumb {
        margin: 0px 15px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        img {
            width: 100%;
            @include transition(.3s);
        }
        &--2 {
            margin: 0px 0px;
        }
    }
    &__content {
        background: $white;
        position: relative;
        padding: 30px 30px;
        @include transition(.3s);
        box-shadow: 0px 10px 30px 0px rgba(2, 24, 95, 0.04);
        &::after {
            left: 0px;
            top: -130px;
            width: 100%;
            height: 130px;
            background: $white;
            content: "";
            position: absolute;
            @include transition(.3s);
            box-shadow: 0px 10px 30px 0px rgba(2, 24, 95, 0.04);
        }
        .date-author {
            color: $theme-color;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 13px;
            display: inline-block;
            margin-bottom: 8px;
        }
        &--2 {
            &::after {
                display: none;
            }
        }
        &--grey {
            background: $grey;
            padding: 33px 45px;
            padding-bottom: 35px;
        }
        &--white {
            box-shadow: none;
            @include respond(md) {
                padding: 33px 45px !important;
                padding-bottom: 35px !important;
            }
            .inline-btn {
                .icon {
                    color: $primary-color !important;
                }
                &:hover {
                    .icon {
                        color: $theme-color !important;
                    }
                }
            }
        }
        .inline-btn {
            .icon {
                color: $primary-color;
            }
        }
    }
    &__cat {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 13px;
        font-weight: 700;
        color: $white;
        display: inline-block;
        background: $theme-color;
        padding: 2px 25px;
        text-transform: uppercase;
    }
    &__title {
        a {
            font-family: $headingfont;
            color: $primary-color;
            line-height: 32px;
            display: inline-block;
        }
        &--2 {
            margin-bottom: 17px;
        }
    }
    .inline-btn {
        margin-top: 22px;
        color: #6B727E;
        .icon {
            &__hover{
                display: none;
            } 
        }
    }
    &:hover {
        .post-box__thumb {
            img {
                @include transform(scale(1.1));
            }
        }
        .post-box__content {
            background: $theme-color;
            &::after {
                background: $theme-color;
            }
            .date-author {
                color: $white;
            }
            &--white {
                background: transparent;
                .inline-btn {
                    color: $primary-color;
                    &:hover {
                        color: $theme-color;
                    }
                    .icon {
                        margin-left: 0px;
                        display: inline-block;
                        &__hover {
                            display: none;
                        }
                    }
                }
                .date-author {
                    color: $theme-color;
                }
            }
            &--2 {
                background: inherit;
            }
        }
        .post-box__title {
            a {
                color: $white;
            }
            &--2 {
                a {
                    color: $primary-color;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
        .inline-btn {
            color: $white;
            .icon {
                color: $white;
            }
        }
    }
    .reverse-col {
        @include respond(md) {
            flex-direction: column-reverse;
        }
    }
}

/*blog content css*/
.blog__area {
    .text-right {
        @include respond(xs) {
            text-align: left !important;
            .site-btn {
                margin-top: 20px;
            }
        }
    }
}
.blog__box {
    .thumb {
        overflow: hidden;
        img {
            @include transition(.3s);
            width: 100%;
        }
    }
    .content {
        border: 2px solid #ddd;
        padding: 40px 28px;
        padding-top: 33px;
        border-top: 0px;
        @include respond(laptop) {
            padding: 40px 25px;
        }
        .cat {
            font-size: 14px;
            color: $theme-color;
            margin-bottom: 15px;
            font-weight: 700;
            font-family: $defaultfont;
            text-transform: uppercase;
        }
        .title {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 10px;
            a {
                color: $primary-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-family: $defaultfont;
        }
        .meta {
            margin-top: 20px;
            @include d-flex;
            align-items: center;
            .authore {
                @include d-flex;
                align-items: center;
                &__thumb {
                    border-radius: 50%;
                }
                &__content {
                    border: none;
                    padding: 0px;
                    padding-left: 15px;
                    h5 {
                        font-family: $defaultfont;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }
            .date {
                font-family: $defaultfont;
                font-weight: 700;
                padding-left: 30px;
                i {
                    color: $theme-color;
                    margin-right: 5px;
                }
            }
        }
    }
    &:hover {
        .thumb img {
            transform: scale(1.1);
        }
    }
    &--2 {
        .content {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    &--3 {
        background: $grey;
        .content {
            border: none;
            padding: 50px 50px;
            padding-right: 40px;
            @include respond(xs) {
                padding: 30px 25px;
            }
            .cat {
                background: $theme-color;
                padding: 5px 27px;
                font-size: 14px;
                display: inline-block;
                margin-bottom: 22px;
                span {
                    color: $white;
                    font-family: $defaultfont;
                }
            }
            .title {
                font-size: 32px;
                line-height: 42px;
                @include respond(xs) {
                    font-size: 18px;
                    line-height: 28px;
                }
                @include respond(sm) {
                    font-size: 24px;
                    line-height: 36px;
                }
                a {
                    font-family: $headingfont;
                    letter-spacing: -2px;
                    @include respond(xs) {
                        letter-spacing: 0px;
                    }
                }
            }
            .meta {
                @include respond(xs) {
                    margin-top: -5px;
                }
                @include respond(sm) {
                    margin-top: 0px;
                }
                span, a {
                    font-size: 14px;
                    color: $primary-color;
                    font-family: $defaultfont;
                    @include respond(xs) {
                        margin-top: 5px;
                    }
                    @include respond(sm) {
                        margin-top: 0px;
                    }
                }
                span {
                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }
            .post-bottom {
                @include d-flex;
                align-items: center;
                justify-content: space-between;
            }
            .authore {
                @include d-flex;
                align-items: center;
                font-weight: 500;
                img {
                    margin-right: 15px;
	                border-radius: 50%;
                }
            }
        }
    }
    &--video {
        .thumb {
            position: relative;
        }
        .video-link {
            left: 50%;
            top: 50%;
            position: absolute;
            @include transform(translate(-50%, -50%));
            .video-play-wrap {
                width: 80px;
                height: 80px;
            }
            .video-play {
                width: 80px;
                height: 80px;
                line-height: 80px;
                background: $theme-color;
                color: $white;
            }
        }
    }
    &--gallery {
        .pixen-nav div {
            font-size: 30px;
            display: inline-block;
            position: absolute;
            z-index: 999;
            cursor: pointer;
            left: 40px;
            top: 50%;
            @include transform(translateY(-50%));
            color: $white;
            &.pixen-next {
                left: auto;
	            right: 40px;
            }
        }
    }
    &--quote {
        background: $theme-color;
        position: relative;
        z-index: 1;
        padding: 50px;
        padding-left: 155px;
        padding-top: 40px;
        @include respond(xs) {
            padding: 30px 25px;
            padding-left: 80px;
        }
        &::after {
            background-image: url(../images/shape/blockquote-patter.png);
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            height: 100%;
            left: 0;
            width: 100%;
            top: 0;
            content: "";
            z-index: -1;
        }
        .quote-icon {
            position: absolute;
            top: 55px;
            left: 50px;
            @include respond(xs) {
                width: 50px;
                top: 40px;
                left: 15px;
            }
        }
        .title {
            a {
                color: $white;
                font-size: 32px;
                font-family: $headingfont;
                @include respond(xs) {
                    font-size: 18px;
                }
                @include respond(sm) {
                    font-size: 24px;
                    letter-spacing: 0px;
                    line-height: 36px;
                }
            }
        }
        .meta {
            span, a {
                font-size: 14px;
                color: $white;
                font-family: $defaultfont;
            }
            span {
                &:not(:last-child) {
                    margin-right: 30px;
                }
            }
        }
        blockquote {
            margin-bottom: 0px;
        }
    }
    &--transparent {
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        .content {
            position: relative;
            z-index: 2;
        }
    }
    &--details {
        padding: 50px;
        @include respond(xs) {
            padding: 15px;
        }
        @include respond(sm) {
            padding: 30px;
        }
        .content {
            padding: 0px;
            p {
                line-height: 26px;
            }
        }
        .title {
            font-size: 36px;
            line-height: 46px;
        }
        blockquote {
            position: relative;
            background: $theme-color;
            padding: 60px 65px;
            font-size: 28px;
            text-align: center;
            font-weight: 700;
            z-index: 1;
            padding-top: 40px;
            @include respond(xs) {
                padding: 40px 35px;
            }
            p {
                color: $white;
                line-height: 38px !important;
            }
            span {
                font-weight: 700;
                font-size: 14px;
                display: inline-block;
                margin-bottom: 15px;
                color: $white;
            }
            .quote-icon {
                left: auto;
                right: 40px;
                z-index: -1;
                top: 35px;
                position: absolute;
                width: 40px;
            }
        }
        .inner-content {
            img {
                width: 100%;
            }
            h4 {
                font-size: 30px;
            }
            ul {
                @include respond(xs) {
                    padding-left: 0px;
                }
                li {
                    @include d-flex;
                    flex-wrap: nowrap;
                    i {
                        margin-right: 10px;
                        transform: translateY(5px);
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .releted-post-heading {
            font-size: 26px;
            letter-spacing: -2px;
        }
        &:hover {
            .thumb {
                img {
                    @include transform(scale(1));
                }
            }
        }
    }
}

.releted-post {
    .blog__box {
        .content {
            padding: 20px 35px;
            padding-bottom: 35px;
            padding-top: 15px;
            background: $white;
            @include transition(.3s);
            @include respond(xs) {
                padding: 30px 20px;
            }
            .title {
                line-height: 30px;
                a {
                    font-size: 22px;
                    letter-spacing: 0px;
                    letter-spacing: -1px;
                }
            }
        }
        &:hover {
            box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
            .thumb {
                img {
                    @include transform(scale(1.1));
                }
            }
        }
    }
}

.blog-author {
    padding: 40px 35px;
    background: $white;
    @include d-flex;
    align-items: center;
    @include respond(md) {
        justify-content: center;
    }
    @include respond(xs) {
        padding: 40px 20px;
    }
    @include respond(sm) {
        padding: 40px;
    }
    &__text {
        padding-left: 40px;
        @include flexWidth(69%);
        @include respond(md) {
            @include flexWidth(100%);
            padding-left: 0px;
            margin-top: 20px;
            text-align: center;
        }
        h3 {
            font-size: 32px;
            margin-bottom: 15px;
            margin-top: 5px;
            letter-spacing: -2px;
            line-height: 26px;
            @include respond(xs) {
                font-size: 24px;
            }
            @include respond(sm) {
                font-size: 32px;
            }
        }
        p {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 28px;
        }
        span {
            color: $theme-color;
            font-size: 12px;
            font-family: $defaultfont;
            font-weight: 700;
        }
    }
}

.blog-post-tag {
	span {
        font-size: 20px;
        font-weight: 500;
        display: block;
        margin-bottom: 17px;
        color: $primary-color;
        font-family: $headingfont;
        letter-spacing: -1px;
    }
    a {
        color: $primary-color;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        border: 2px solid #ddd;
        padding: 4px 24px;
        margin-right: 6px;
        @include transition(.3s);
        margin-bottom: 10px;
        position: relative;
        z-index: 1;
        font-family: $defaultfont;
        background: $grey;
        &:hover {
            background: $theme-color;
            color: $white;
            border-color: $theme-color;
        }
    }
}

.blog-share-icon {
    span {
        font-size: 20px;
        font-weight: 600;
        color: $primary-color;
        display: block;
        margin-bottom: 17px;
        font-family: $headingfont;
        letter-spacing: -1px;
    }
    a {
        color: #B5BECC;
        font-size: 18px;
        @include transition(.3s);
        margin-right: 22px;
        &:last-child {
            margin-right: 0px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.bakix-navigation  {
    span {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        margin-bottom: 0px;
        a {
            color: $theme-color;
            font-family: $defaultfont;
            text-transform: capitalize;
        }
    }
    h4 {
        font-size: 24px;
        margin: 0;
        letter-spacing: -2px;
    }
    a {
        color: $primary-color;
        font-family: $headingfont;
        &:hover {
            color: $theme-color;
        }
    }
}

.navigation-border {
    border-top: 2px solid #ddd;
    overflow: hidden;
}

/*Pagination css*/
.blog__pagination {
    @include respond(lg) {
        margin-bottom: 50px;
    }
    ul {
        @include d-flex;
        align-items: center;
        justify-content: center;
        li {
            &:not(:last-child) {
                margin-right: 10px;
            }
            &:hover, &.active {
                a {
                    background: $theme-color;
                    color: $white;
                }
            }
        }
        a {
            font-size: 14px;
            color: $primary-color;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            display: block;
            background: $white;
            @include respond(xs) {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
            @include respond(sm) {
                width: 50px;
                height: 50px;
                line-height: 50px;
            }
        }
    }
}

/*Sidebar css*/
.widget {
    .aboutme {
        img {
            border-radius: 50%;
        }
        h4 {
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 25px;
        }
        &__icon {
            @include d-flex;
            justify-content: center;
            align-items: center;
            a {
                font-size: 14px;
                color: #B3BED3;
                &:hover {
                    color: $theme-color;
                }
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }
    .cat {
        li {
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        a {
            display: block;
            color: $primary-color;
            font-weight: 500;
            background: $grey;
            padding: 12px 30px;
            padding-right: 0;
            font-size: 14px;
            font-family: $defaultfont;
            &:hover {
                background: $theme-color;
                color: $white;
            }
            span {
                background: $theme-color;
                width: 50px;
                text-align: center;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                line-height: 50px;
                color: $white;
                @include transition(.3s);
                font-size: 14px;
            }
        }
    }
    .social__links {
        @include d-flex;
        align-items: center;
        justify-content: flex-start;
        a {
            font-size: 14px;
            width: 40px;
            line-height: 40px;
            height: 40px;
            color: $primary-color;
            background: $grey;
            text-align: center;
            &:not(:last-child) {
                margin-right: 10px;
            }
            &:hover {
                background: $theme-color;
                color: $white;
            }
        }
    }
    .twitter__feeds {
        li {
            @include d-flex;
            align-items: flex-start;
            &:not(:last-child) {
                margin-bottom: 30px;
                border-bottom: 1px solid #ddd;
                padding-bottom: 30px;
            }
        }
        .t-feed-icon {
            font-size: 14px;
            color: $theme-color;
            margin-top: 3px;
        }
        .t-feed-body {
            padding-left: 20px;
            @include flexWidth(95%);
            @media (max-width: 400px) {
                @include flexWidth(100%);
                padding-left: 0px;
                margin-top: 15px;
            }
        }
        .t-feed-meta {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            display: inline-block;
            margin-top: 10px;
        }
    }
    #Instafeed {
        margin: 0 -5px;
        overflow: hidden;
        li {
            width: 33.33%;
            float: left;
            padding: 0 5px;
            margin-bottom: 10px;
            img {
                width: 100%;
            }
        }
    }
    .tag {
        margin-top: -10px;
        .site-btn {
            margin-top: 10px;
            font-size: 12px;
            height: 35px;
            line-height: 34px;
            text-transform: uppercase;
            padding: 0px 18px;
            background: $white;
            color: $primary-color;
            &:hover {
                background: $theme-color;
                color: $white;
            }
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
    &.ad__widget {
        padding: 0;
        position: relative;
        img {
            width: 100%;
        }
        .ad-text {
            position: absolute;
            top: 50%;
            @include transform(translate(-50%, -50%));
            left: 50%;
            background: $theme-color;
            text-align: center;
            padding: 19px 15px;
            text-transform: uppercase;
            width: calc(100% - 135px);
            h3 {
                color: $white;
            }
            span {
                font-size: 16px;
                display: block;
                text-align: center;
                margin-bottom: 8px;
                color: $white;
            }
        }
    }
}

.sidebar-search-form {
    position: relative;
    input {
        width: 100%;
        font-size: 12px;
        background: $white;
        color: $primary-color;
        border: none;
        border-radius: 0px;
        padding: 17px 30px;
        color: $primary-color;
        @include placeholder($primary-color !important);
    }
    button {
        border: none;
        position: absolute;
        right: 0;
        background: $theme-color;
        color: $white;
        font-size: 14px;
        width: 60px;
        height: 60px;
        line-height: 63px;
        &:hover {
            background: $primary-color;
            color: $white;
        }
    }
}

.recent-posts {
    li {
        @include d-flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 30px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 30px;
        }
    }
    .thumb {
        overflow: hidden;
    }
    .content {
        padding-left: 20px;
        @include flexWidth(72%);
        @media (max-width: 400px) {
            @include flexWidth(100%);
            padding-left: 0px;
            margin-top: 15px;
        }
        .title {
            a {
                color: $primary-color;
                line-height: 22px;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        .meta {
            font-size: 14px;
            margin-top: 10px;
        }
    }
}

/*Comments css*/
.post-comments {
    padding-bottom: 55px;
    .title {
        font-size: 26px;
        margin-bottom: 25;
        letter-spacing: -2px;
    }
}

.latest__comments {
    .comments-box {
        border-top: 1px solid #ddd;;
        padding: 30px 0;
    }
    .comments-text {
        overflow: hidden;
    }
    .comments-avatar {
        float: left;
        @include respond(xs) {
            float: none;
            margin-bottom: 10px;
        }
        @include respond(sm) {
            float: left;
            margin-bottom: 0px
        }
        img {
            width: 100px !important;
            margin-right: 30px;
        }
    }
    li {
        &:last-child .comments-box {
            padding-bottom: 0px;
        }
        &:first-child .comments-box {
            border-top: 0;
            padding-top: 0;
        }
        &.children {
            margin-left: 100px;
            @include respond(xs) { 
                margin-left: 0px;
            }
            @include respond(sm) { 
                margin-left: 50px;
            }
        }
    }
    
    .avatar-name {
        margin-bottom: 15px;
        overflow: hidden;
        position: relative;
        h5 {
            font-size: 18px;
            margin-bottom: 7px;
            font-weight: 700;
        }
        span {
            font-size: 12px;
            font-weight: 700;
            font-family: $defaultfont;
        }
    }
    .reply {
        color: $primary-color;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        @include transition(.3s);
        line-height: 1;
        position: absolute;
        right: 0;
        margin-top: 0;
        top: 0;
        i {
            margin-right: 5px;
        }
        &:hover {
            color: $theme-color;
        }
    }
    &--2 {
        p {
            font-family: $defaultfont;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.comments__form {
    .title {
        font-size: 26px;
        margin-bottom: 25px;
    }
    .form {
        background: $grey;
        @include respond(xs) {
            padding: 15px;
        }
        @include respond(sm) {
            padding: 30px;
        }
        input {
            border: none;
            height: 60px;
            margin-bottom: 30px;
            padding: 0 30px;
            width: 100%;
            font-size: 14px;
            color: $primary-color;
            border-radius: 0px;
            background: $white;
            border-radius: 0px;
        }
        textarea {
            border: none;
            height: 150px;
            margin-bottom: 20px;
            padding: 30px;
            width: 100%;
            color: $primary-color;
            font-size: 14px;
            background: $white;
            border-radius: 0px;
        }
        .contact-icon {
            position: relative;
            &::after {
                position: absolute;
                content: "";
                font-family: "Font Awesome 5 Pro";
                font-weight: 700;
                display: inline-block;
                font-size: 16px;
                text-align: center;
                right: 30px;
                color: $theme-color;
                top: 18px;
                font-weight: 300;
            }
            &::before {
                display: none;
            }
        }
        .contacts-message::after {
            content: "\f303";
        }
        .contacts-name::after {
            content: "\f007";
        }
        .contacts-email::after {
            content: "\f0e0";
        }
        .contacts-website::after {
            content: "\f0ac";
        }
    }
}

.mmt-30 {
    @include respond(md) {
        margin-top: 30px !important;
    }
}