// font family
$defaultfont: 'Rubik', sans-serif;
$headingfont: 'Montserrat', sans-serif;

// Colors variable
$primary-color: #0C2957;
$pera-color: #6B727E;
$white: #fff;
$black: #1F1F20;
$grey:#F7F7F7;
$green:#37B546;
$yellow:#FFB400;
$blue:#0084FF;
$blue-dark:#0D2F64;
$theme-color: #8d7ecb;
