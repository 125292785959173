/* Main container styling */
.services-details-content {
  position: relative;
  /* Total height for the entire component */
  /* height: 2691px;  */
  height: 2475px;
  overflow-y: hidden;
}

/* Each section styling */
.service-section {
  /* Fixed height for each section */
  /* height: 897px;  */
  height: 825px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.service-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  padding: 20px;
}

.service-image img {
  width: 600px;
  height: 600px;
  object-fit: cover;
}

.service-text {
  flex: 1;
}

.service-text h2 {
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0;
  text-align: left;
}

.service-text p {
  font-size: 1rem;
  line-height: 1.6;
}

.special {
  background-color: #8d7ecb;
}

.special h2, .special p{
  color: #fff !important;
}

.service-content.reverse {
  flex-direction: row-reverse;
}

.button-container {
  margin-top: 20px;
  margin-left: 0px;
}

.service-button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 12px 24px;
  color: var(--awb-color8);
  background-color: #fff;
  border: 1px solid var(--awb-color8);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service-button .button-icon-right {
  margin-left: 10px;
}

/* Mobile View */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column !important;
    text-align: center;
  }
  .service-image img {
    width: 100%;
    height: auto;
  }

  .service-text{
    text-align: center;
  }

  .service-text h2{
    text-align: center;
  }

  .services-details-content {
    height: auto;
  }
}
