/*brand css*/
.brand-carousel {
    &__item {
        min-height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: auto !important;
        }
        .hover {
            display: none !important;
        }
        &:hover {
            .hover {
                display: inline-block !important;
            }
            .default {
                display: none !important;
            }
        }
    }
}