/* typography css start */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: $primary-color;
  margin: 0;
  line-height: 1.4;
  font-family: $headingfont;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}
/* typography css end */
