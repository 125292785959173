/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
}
/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: #0c1923;
	padding: 4px 0;
	z-index: 999999;
}
.mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}
.mean-container a.meanmenu-reveal span {
	display: block;
	background: #004976;
	height: 3px;
	width: 30px;
}
.mean-container .mean-nav {
	float: left;
	width: 100%;
	background: #0c1923;
	margin-top: 44px;
}
.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}
.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}
.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 12px 5%;
	margin: 0;
	text-align: left;
	color: #fff;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
}
.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 10px 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
}
.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}
.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255, 255, 255, 0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 32px;
	padding: 6px 10px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: rgba(255, 255, 255, 0.1);
	border: none !important;
	border-left: 1px solid rgba(255, 255, 255, 0.4) !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
	line-height: 35px;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
	background: rgba(0, 0, 0, 0.9);
}
.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}
.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}
/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.mean-remove {
	display: none !important;
}
.mean-container a.meanmenu-reveal {
	border: 1px solid #086AD8;
	width: 30px;
	padding: 13px 10px;
	height: auto;
}
.mean-container .mean-bar {
	background: transparent;
}

.mean-container a.meanmenu-reveal span {
	background: #086AD8;
	margin-top: 5px;
}

.mean-container .mean-bar {
	padding: 0;
}
.mean-container a.meanmenu-reveal {
	color: #086AD8;
}
.mean-container a.meanmenu-reveal {
	margin-top: -41px;
	padding: 0;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-right: 0px;
}
.mean-container a.meanmenu-reveal span:first-child {
	margin-top: 0;
}
.mean-container .mean-nav {
	margin-top: 0;
    position: absolute;
    transform: translateY(12px);
}